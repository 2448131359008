import React from 'react';
import {
    withRouter
} from 'react-router-dom';
import { NavBar, Icon, List } from 'antd-mobile';
import hd from '../../asset/hd.png';
import tv from '../../asset/tv.png';
import VideoPlayer from '../../component/VideoPlayer';
import 'video.js/dist/video-js.min.css';
import './DetailPage.css'

const Item = List.Item;



class DetailPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            src:''
        };

        
    }

    playVideo = (src) => {
        this.setState({
            loading: true,
        })

        this.videoJsOptions = {
            autoplay: true,
            controls: true,
            sources: [{
            src: src
            }]
        }

        console.log(src)
    }

    playRMTP = (src) => {

        this.setState({
            loading: true,
        })

        this.videoJsOptions = {
            autoplay: true,
            controls: true,
            sources: [{
				type: 'rtmp/mp4',
				src: src
            }],
            techOrder: ['flash'],
            
        }

        console.log(src)

    }
    
    
    render() {
        return(
            <div>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >
                     {this.props.location.state.name}
                </NavBar>

                <div className='videoBox'>

                    {
                        this.state.loading 
                        ?
                        <VideoPlayer { ...this.videoJsOptions } />
                        :
                        null
                    }

                </div>

                <List renderHeader={() => 'HLS 直播源'} >
                    <Item
                        arrow = "horizontal"
                        thumb = {tv}
                        onClick={() => {this.playVideo(this.props.location.state.hls)}}
                    >
                        标清线路
                    </Item>

                    <Item
                        arrow = "horizontal"
                        thumb = {hd}
                        onClick={() => {this.playVideo(this.props.location.state.hlsHd)}}
                    >
                        高清线路
                    </Item>
                </List>

                {/* <List renderHeader={() => 'RTMP 直播源（需要安装 flash 插件）'} >
                    <Item
                        arrow = "horizontal"
                        thumb = {tv}
                        onClick={() => {this.playRMTP(this.props.location.state.rtmp)}}
                    >
                        标清线路
                    </Item>

                    <Item
                        arrow = "horizontal"
                        thumb = {hd}
                        onClick={() => {this.playVideo(this.props.location.state.rtmpHd)}}
                    >
                        高清线路
                    </Item>
                </List> */}

                {/* <List renderHeader={() => 'FLV 直播源（需要安装 flash 插件）'} >
                    <Item
                        arrow = "horizontal"
                        thumb = {tv}
                        onClick={() => {this.playVideo(this.props.location.state.flvAddress)}}
                    >
                        标清线路
                    </Item>

                    <Item
                        arrow = "horizontal"
                        thumb = {hd}
                        onClick={() => {this.playVideo(this.props.location.state.hdFlvAddress)}}
                    >
                        高清线路
                    </Item>
                </List> */}
                

                

                

            </div>
        );
    };
}

export default withRouter(DetailPage)