import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './pages/App';
import * as serviceWorker from './serviceWorker';
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom'

class Root extends React.Component {
  render() {
      return (
          <Router>
              <Route path='/' component={App} />
          </Router>
      );
  };
}

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.register();
