let API_ROUTE = "https://open.ys7.com/api/lapp/";

export const APP_KEY = '5a906c002b004aa78d447cd5a0b11d74'
export const APP_SECRET = '1d649490fc570f64be31976b68c99c6b'

export const API_AT = API_ROUTE + 'token/get';

// 设备列表
export const API_DEVICE_LIST = API_ROUTE + 'device/list';

// 摄像头列表
export const API_CAMERA_LIST = API_ROUTE + 'camera/list'

// 获取直播地址
export const API_LIVE_GET = API_ROUTE + 'live/address/get'

// 开通直播功能
export const API_LIVE_OPEN = API_ROUTE + 'live/video/open'