import axios from 'axios'

class Http{
    static getDataFromServer(apiUrl, configObj) {
        let {
            method = 'POST',
            params = {},
            data = {},
            // timeout = 5000
        } = configObj;
    
        return new Promise(function(resolve, reject){
            axios({
                url: apiUrl,
                method: method,
                params: params,
                data: data,
                // timeout: timeout,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', 
                }
            }).then(function(response){
                if(response) {
                    if (response.data && response.data.code) {
                        
                        if(response.data.code === 'login_fail') {
                
                            alert('账号密码错误或登陆状态失效')
                            localStorage.removeItem('token')
                            
                            setTimeout(function(){
                                window.location.href="/login"
                            }, 1000)
                        }
    
                        if(response.data.code === 'account_frozen') {
    
                            alert('账号已被封禁')
                            // localStorage.removeItem('token')
                            
                            setTimeout(function(){
                                window.location.href="/login"
                            }, 1000)
                        }
    
                        resolve(response);
    
                    }else {
                        resolve(response);
                        alert('数据错误')  
                    }
                }else {
                    //处理特殊的情况就是response返回什么也没有
                    resolve(response);
                    alert('服务器错误')
                }
            }).catch(function(error){
    
                // 网络异常
                reject(error);
                alert('网络错误')
    
            })
        }
    
        )
    }
}

export default Http;


    
