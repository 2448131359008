import React from 'react';
import {
  Route,
  Switch,
  withRouter,
} from 'react-router-dom'
import IndexPage from './Index/IndexPage'
import DetailPage from './Detail/DetailPage'

class App extends React.Component {
  render() {
      return (
          <div>
              <Switch>
                  <Route exact path="/" component={IndexPage}/>
                  <Route path="/index" component={IndexPage}/>
                  <Route path="/detail" component={DetailPage}/>
              </Switch>
          </div>
      );
  }
}

export default withRouter(App);
