import React from 'react';
import {
    withRouter
} from 'react-router-dom';
import './IndexPage.css'
import { SearchBar, List, Toast, Modal } from 'antd-mobile';
import { RotateSpinner } from "react-spinners-kit";
import { APP_KEY, APP_SECRET, API_AT, API_CAMERA_LIST, API_LIVE_GET, API_LIVE_OPEN } from './../../config/api.conf'
import Http from '../../utils/Http'
import qs from 'qs'
import camera from '../../asset/camera.png'
import camera_dark from '../../asset/camera_dark.png'

const Item = List.Item;
const Brief = Item.Brief;
const alert = Modal.alert;

class IndexPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            list: [],
            group: [],
            originData: [],
            // searchValue: ''
        };
    }

    getFirtTimeData = () => {

        let reqData = {
            data: qs.stringify({
                appKey: APP_KEY,
                appSecret: APP_SECRET
              }),
        }

        Http.getDataFromServer(API_AT, reqData).then((reponse) => {

            if (reponse.data.code === '200') {

                var at = localStorage.getItem('at');

                if (at !== reponse.data.data.accessToken) {
                    localStorage.setItem('at', reponse.data.data.accessToken);
                }

                this.getDeviceInfo(reponse.data.data.accessToken);

            } 

        }).catch((error) => {
            console.log(error);
        })


    }

    getAllDeviceInfo = (max, at) => {

        var dataArr = [];

        for (var i = 0 ; i < max ; i ++ ) {

            let reqData = {
                data: qs.stringify({
                    accessToken: at,
                    pageStart: i,
                    pageSize: 50
                  }),
            }
    
            // eslint-disable-next-line no-loop-func
            Http.getDataFromServer(API_CAMERA_LIST, reqData).then((reponse) => {
                
                if (reponse.data.code === '200') {

                    dataArr = dataArr.concat(reponse.data.data) 
                    
                    // for (var item in dataArr) {
                    //     // if (item.channelName.includes('酒店')) {
                    //     //     console.log(item)
                    //     // }
                    //     console.log(item.channelName)
                    // }

                    this.setState({
                        list: dataArr,
                        loading: false,
                        originData: dataArr
                    })

                }
    
            }).catch((error) => {
                console.log(error);
            })

        }

    }

    getDeviceInfo = (at) => {

        let reqData = {
            data: qs.stringify({
                accessToken: at,
                pageStart: 0,
                pageSize: 1
              }),
        }

        Http.getDataFromServer(API_CAMERA_LIST, reqData).then((reponse) => {

            if (reponse.data.code === '200') {

                this.getAllDeviceInfo(parseInt(reponse.data.page.total / 50) + 1, at)
                
            }else {
                this.getFirtTimeData();
            }

        }).catch((error) => {
            console.log(error);
        })

    }

    search = (value) => {
        
        var searchResult = [];

        for (var i = 0 ; i < this.state.originData.length ; i++) {
            if (this.state.originData[i].channelName.indexOf(value) != -1 ) {
                searchResult.push(this.state.originData[i])
            }
        }

        if (searchResult.length == 0) {
            Toast.fail('设备列表中不存在该设备', 3);
        }else {
            this.setState({
                list: searchResult
            })
        }

    }

    cancelSearch = () => {

        this.setState({
            list: this.state.originData,
            // searchValue: ''
        })

    }

    showAlert = (sourceStr) => {

        alert('该设备尚未开通直播功能', '是否立即开通?', [
            { text: '取消', onPress: () => console.log('cancel') },
            { text: '开通', onPress: () => this.openLive(sourceStr) },
          ])

    }

    openLive = (sourceStr) => {
        console.log('开通直播')

        let reqData = {
            data: qs.stringify({
                accessToken: localStorage.getItem('at'),
                source: sourceStr
              }),
        }

        Http.getDataFromServer(API_LIVE_OPEN, reqData).then((reponse) => {

            if (reponse.data.code === '200') {

                if (reponse.data.data[0].ret == 200) {
                    Toast.success(reponse.data.data[0].desc, 3);
                }else {
                    Toast.fail(reponse.data.data[0].desc, 3);
                }

                
                
                
            }

        }).catch((error) => {
            console.log(error);
        })
    }

    getLiveData = (deviceSerial, channelNo) => {
        var sourceStr = deviceSerial + ':' + channelNo

        let reqConf = {
            data: qs.stringify({
                accessToken: localStorage.getItem('at'),
                source: sourceStr
              })
        }

        Http.getDataFromServer(API_LIVE_GET, reqConf).then((response) => {

            if (response.data.code === '200') {

                if (response.data.data[0].ret == 200) {
                    var hls = response.data.data[0].hls
                    var hlsHd = response.data.data[0].hlsHd
                    var rtmp = response.data.data[0].rtmp
                    var rtmpHd = response.data.data[0].rtmpHd
                    var flvAddress = response.data.data[0].flvAddress
                    var hdFlvAddress = response.data.data[0].hdFlvAddress
                    var name = response.data.data[0].deviceName

                    this.props.history.push({ pathname : '/detail', state : { hls: hls, hlsHd: hlsHd, rtmp: rtmp, rtmpHd: rtmpHd, flvAddress: flvAddress, hdFlvAddress: hdFlvAddress, name: name }})

                }else {

                    if (response.data.data[0].ret == 60060) {

                        this.showAlert(sourceStr)
                        
                    }else {

                        Toast.fail(response.data.data[0].desc, 3);

                    }

                    

                }

            }

        }).catch((error) => {
            alert(error)
        })
    }


    componentDidMount() {

        if (!localStorage.getItem('at')) {
            this.getFirtTimeData();
        }else {
            this.getDeviceInfo(localStorage.getItem('at'));
        }

    }
    
    
    render() {
        return(
            <div>

                <SearchBar 
                    placeholder="搜索"
                    // value={this.state.searchValue}
                    maxLength={20}
                    onSubmit={value => this.search(value)}
                    // onFocus={this.focus}
                    onCancel={this.cancelSearch} 
                />

                {this.state.loading
                    ?
                    <div style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-30%, -50%)'}}>
                        <RotateSpinner
                            size={40}
                            color="#2A82E4"
                            loading={this.state.loading}
                        />
                    </div>
                    :
                    <div>
                        <List renderHeader={() => '区域列表'} 
                              className="my-list"
                        >
                    
                        {this.state.list.map((data, i)  =>  <Item arrow = "horizontal" 
                                                                  thumb = {this.state.list[i].status == 1 ? camera : camera_dark} 
                                                                  alt='error' 
                                                                  key={i}
                                                                  onClick={() => {this.getLiveData(this.state.list[i].deviceSerial, this.state.list[i].channelNo)}}
                                                            >
                                                            {this.state.list[i].channelName}
                                                                <Brief>
                                                                    {this.state.list[i].deviceSerial}
                                                                </Brief>
                                                            </Item> )}

                        </List>

                        {/* <div className="pagination-container" >
                            <Pagination total={5}
                                        className="custom-pagination-with-icon"
                                        current={1}
                                        locale={{
                                            prevText: (<span className="arrow-align"><Icon type="left" />上一页</span>),
                                            nextText: (<span className="arrow-align">下一页<Icon type="right" /></span>),
                                        }}
                            />
                        </div> */}

                    </div>
                    

                }


            </div>
        );
    };
}

export default withRouter(IndexPage)